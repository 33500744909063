<template>
  <div>
    <iframe :srcdoc="paymentProcessHtml"></iframe>
  </div>
</template>

<script>
import { uiHelper, locale } from '@/util'
export default {
  name: 'process',
  metaInfo: {
    title: `${locale.getMessage('meta.deposit_title')}`,
    titleTemplate: `%s`,
    meta: [{ charset: 'utf-8' }, { name: 'description', content: `${locale.getMessage('meta.deposit_description')}` }, { name: 'keyword', content: `${locale.getMessage('meta.deposit_keyword')}` }]
  },

  date: () => ({
    paymentProcessHtml: ''
  }),
  created() {
    this.paymentProcessHtml = this.$route.query.html
  }
}
</script>
